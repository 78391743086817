<div class="modal-header">
  <h2 ngbAutofocus class="modal-title flex-fill text-center">
    {{ getModalHeader() }}
  </h2>
  <button *ngIf="!showExitConfirmationModal" id="closeModal" type="button" class="btn close" aria-label="Close" (click)="onCloseModal()">
    <i class="material-icons">close</i>
  </button>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col pt-3">
    <div class="col pt-3">
      <div class="spinner-border text-gray" role="status"></div>
      <p class="small text-gray">Loading...</p>
    </div>
  </div>
</div>
<ng-container *ngIf="!isLoading && !showEnrolledFactor">
  <div class="modal-body">
    <div class="alert alert-danger" role="alert" *ngIf="mfaTechDifficultiesStatus">
      <span class="alert-icon material-icons-round"><i class="alert-icon material-icons">warning</i></span>
      <div class="text">We can't complete your request at this time. Please try again later.</div>
    </div>
    <div class="custom-modal-body">
      To continue, you'll need to verify your identity using multifactor authentication (MFA).
    </div>
  </div>
  <div class="modal-footer justify-content-right">
    <button id="btnMFAVerificationCancel" type="button" class="btn btn-secondary" (click)="routeToSignIn()">
      Cancel
    </button>
    <button
      id="btnMFAVerificationContinue"
      #btnMFAVerificationContinue
      type="button"
      class="btn btn-primary"
      (click)="getEnrolledFactorList()"
    >
      Continue
    </button>
  </div>
</ng-container>

<div class="modal-body" *ngIf="!isLoading && showEnrolledFactor && !showExitConfirmationModal && !selectedFactor">
  <div class="custom-modal-body">
    <h6 class="mb-5">Select a security method</h6>
    <ng-container *ngFor="let list of sortedList; let isLast = last">
      <div [ngClass]="[isLast ? 'pb-5' : 'pb-4', 'security-method']">
        <img src="{{ list.icon }}" alt="Google Authenticator" class="mr-4" />
        <div class="text">
          <p class="mb-0">{{ list.text }}</p>
          <p *ngIf="list.value" class="small mt-1 mb-0">{{ list.value }}</p>
        </div>
        <button id="google-button" type="button" class="btn btn-secondary btn-sm" (click)="handleSelectFactor(list)">
          Select
        </button>
      </div>
    </ng-container>
    <a class="signin-link" (click)="routeToSignIn()">Sign In</a>
    <p class="mt-5 mb-0 need-help">
      Need help? Contact Customer Service at
      <a href="tel:8555222677" class="d-inline-block d-sm-none">855-522-2677</a
      ><span class="d-none d-sm-inline-block text-nowrap">855-522-2677</span>.
    </p>
  </div>
</div>

<patient-ui-exit-confirmation-modal *ngIf="showExitConfirmationModal" (cancel)="routeToSignIn()" (back)="onBack()">
</patient-ui-exit-confirmation-modal>

<ng-container *ngIf="selectedFactor && errorCount < 3 && !showExitConfirmationModal && !verifiedStatus">
  <ng-container [ngSwitch]="selectedFactor">
    <patient-ui-google-auth-verification
      *ngSwitchCase="'googleAuth'"
      (submitCode)="verifyFactor($event)"
      (showFactorList)="showFactorList()"
      [isLoading]="(mfaLoading$ | async) || false"
      [verifiedStatus]="(verifiedFactorStatus$ | async) || false"
      [verifiedError]="(verifiedFactorError$ | async) || false"
      [verifyErrorCount]="(verifyFactorErrorCount$ | async) || 0"
    ></patient-ui-google-auth-verification>

    <patient-ui-okta-code-verification
      *ngSwitchCase="'oktaCode'"
      (submitCode)="verifyFactor($event)"
      (showFactorList)="showFactorList()"
      [isLoading]="(mfaLoading$ | async) || false"
      [verifiedStatus]="(verifiedFactorStatus$ | async) || false"
      [verifiedError]="(verifiedFactorError$ | async) || false"
      [verifyErrorCount]="(verifyFactorErrorCount$ | async) || 0"
    ></patient-ui-okta-code-verification>

    <patient-ui-okta-push-verification
      *ngSwitchCase="'oktaPush'"
      (submitCode)="verifyFactor($event)"
      (showFactorList)="showFactorList()"
      [isLoading]="(mfaLoading$ | async) || false"
      [verifiedStatus]="(verifiedFactorStatus$ | async) || false"
      [verifiedError]="(verifiedFactorError$ | async) || false"
      [verifyErrorCount]="(verifyFactorErrorCount$ | async) || 0"
      [oktaID]="oktaID"
      [factorID]="selectedFactorID"
    ></patient-ui-okta-push-verification>

    <patient-ui-phone-verification
      *ngSwitchCase="'phone'"
      (submitCode)="verifyFactor($event)"
      (showFactorList)="showFactorList()"
      [maskedNumber]="maskedNumber"
      [isLoading]="(mfaLoading$ | async) || false"
      [verifiedStatus]="(verifiedFactorStatus$ | async) || false"
      [verifiedError]="(verifiedFactorError$ | async) || false"
      [verifyErrorCount]="(verifyFactorErrorCount$ | async) || 0"
    ></patient-ui-phone-verification>
  </ng-container>
</ng-container>

<patient-ui-not-verified *ngIf="errorCount >= 3" (cancel)="routeToSignIn()"></patient-ui-not-verified>
<patient-ui-verification-confirmation
  *ngIf="verifiedStatus"
  (submitForm)="submitRegistrationForm()"
  (cancel)="routeToSignIn()"
></patient-ui-verification-confirmation>
