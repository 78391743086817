<div class="modal-body">
  <p class="error-message">
    <span class="material-icons icon-lg text-danger pr-2">error_outline</span>
    <span>We couldn't verify your identity. Try again later.</span>
  </p>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnClose" type="button" class="btn btn-primary" (click)="cancel.emit()">
    Close
  </button>
</div>
