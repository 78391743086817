<div class="modal-body">
  <p>If you exit now, your changes will not be saved.</p>
  <p class="mb-0">Are you sure you want to exit?</p>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnGoBack" type="button" class="btn btn-secondary" (click)="back.emit()">
    Go Back
  </button>
  <button id="btnExit" type="button" class="btn btn-primary" (click)="cancel.emit()">
    Exit
  </button>
</div>
